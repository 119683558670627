<template>
    <Layout :pagetitle="'Subscription Plan Inclusion'">
      <div class="row">

        
        <div id="table-ecommerce-shops">
                <div role="complementary" class="gridjs gridjs-container">
                  <div class="gridjs-head">
                    <div class="gridjs-search">
                      <input 
                        placeholder="Type a keyword..." 
                        aria-label="Type a keyword..."  
                        class="gridjs-input gridjs-search-input" 
                        v-model = "search"
                        type="text"
                        />
                    </div>
                  </div>
                  <div class="gridjs-wrapper" style="height: auto">
                  <div v-if="success != false"
                      class="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                    {{`Inclusion ${success}`}}
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        @click="unSubscribeSuccess"
                  ></button>
                  </div>
                    <table role="grid" class="gridjs-table table table-hover" style="height: auto;width: 100%;">
                      <thead class="gridjs-thead">
                        <tr class="gridjs-tr">
                         <th
                            data-column-id="email"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 06px; width: auto"
                          >
                            <div class="gridjs-th-content">Subscriber</div>
                          </th>
                          <th
                            data-column-id="name"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 44px; width: 10%"
                          >
                            <div class="gridjs-th-content">Transaction #</div>
                          </th>
                          <th
                            data-column-id="email"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 06px; width:20%"
                          >
                            <div class="gridjs-th-content">Plan</div>
                          </th>
                          <th
                            data-column-id="email"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 06px; width: 10%"
                          >
                            <div class="gridjs-th-content">Amount Paid</div>
                          </th>
                          <th
                            data-column-id="email"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 06px; width: 10%"
                          >
                            <div class="gridjs-th-content">Payment Method</div>
                          </th>
                          <th
                            data-column-id="date"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 98px; width: 10%"
                          >
                            <div class="gridjs-th-content">Date Started</div>
                          </th>
                          <th
                            data-column-id="date"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 98px; width: 10%"
                          >
                            <div class="gridjs-th-content">Expiration</div>
                          </th>
            
                          <th
                            data-column-id="product"
                            class="gridjs-th gridjs-th-sort"
                            tabIndex="0"
                            style="minwidth: 91px; width: 7%"
                          >
                            <div class="gridjs-th-content text-center">Action</div>
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody class="gridjs-tbody">
                        
                        <tr v-for="(item, i) in subscriber" :key="i" class="gridjs-tr">
                          <td  class="gridjs-td" >
                            <span>
                                <img
                                :src="item.image"
                                alt=""
                                class="avatar-sm rounded-circle me-2"
                                />
                                <a to="#" class="text-body">{{item.email}}</a><br>
                            </span>
                          </td>
                          <td  class="gridjs-td" >
                            <b-button class="btn btn-soft-default btn-rounded btn-sm font-size-13">
                                <i class="mdi mdi-eye font-size-16 "></i>
                                {{item.transaction_id}}
                              </b-button>
                          </td>
                          <td  class="gridjs-td" >
                              <b-button class="btn btn-soft-primary btn-rounded btn-sm font-size-13">
                                <i class="mdi mdi-eye font-size-16"></i>
                                {{`${item.plan_name} - (${item.type.toUpperCase()})`}}
                              </b-button>
                          </td>
                          <td  class="gridjs-td" >
                            {{currencyFormat(item.amount, item.currency)}}
                          </td>
                          <td  class="gridjs-td" >
                            <span class="  badge badge-pill  font-size-15" 
                            :class="paymentMethods(item.payment_method)">{{item.payment_method}}</span>
                          </td>
                          <td  class="gridjs-td">
                            {{formatDate(item.start_date)}}
                          </td>  
                          <td  class="gridjs-td">
                            {{formatDate(item.expired_date)}}
                          </td>
              
                          <td data-column-id="action" class="gridjs-td">
                            <span class="text-center">
                             <div class="dropdown">
                                  <a href="#" class="dropdown-toggle card-drop" data-bs-toggle="dropdown" aria-expanded="false"><i class="mdi mdi-dots-vertical font-size-18"></i></a>
                                  <ul class="dropdown-menu dropdown-menu-end" style="">
                                    <li><a href="javascript:void(0)" class="dropdown-item" @click="showModalEdit(item)"><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit</a></li>
                                    <li><a href="javascript:void(0)" class="dropdown-item" @click="deleteInclusion(item.id)"><i class="mdi mdi-trash-can font-size-16 text-danger me-1"></i> Delete</a></li></ul>
                                  </div>
                            </span>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                </div>
              </div>
                  
              <div class="col-10">
                <b-pagination
                            class="text-righ"
                            v-model="currentPage"
                            :total-rows="pagination.rows"
                            :per-page="pagination.perPage"
                            :limit="5"
                            @update:modelValue="changePage"
                  ></b-pagination>
              </div>
              <div class="col-2 ">
                <div class="row text-right">
                <label class="col-md-6 col-form-label"># of Rows</label>
                <div class="col-md-6">
                  <select  v-model="rows" @change="changeRows" class="form-select">
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                  </select>
                </div>
              </div>
              </div>
      </div>
  
      <!-- end row -->
   
    </Layout>
  </template>
  <script>
    import Layout from "../../../layouts/main";

    import { useStore } from 'vuex'
    import {ref,computed,reactive,onMounted,watch} from 'vue';
    import { useDebouncedRef } from "../../../../components/utility/debounce";
    import moment from 'moment'
    import Swal from 'sweetalert2'
  
    export default {
      components:{
      
        Layout
      },
      setup() {
        const modalCreate = ref(false)
        const modalEdit = ref(false)
        const search = useDebouncedRef('',400)
        const edit = reactive({
          id:null,
          title:''
        })
        const rows = ref('15')
        const currentPage = ref(1)
   
        const store = useStore()
        const showModalCreate = () =>{
          modalCreate.value = true;
        }
        const showModalEdit = (index) =>{
          Object.assign( edit,{id:index.id,title:index.title});
          console.log(edit)
  
          modalEdit.value = true;
        }
        function hideModal(){
          modalCreate.value = false;
          modalEdit.value = false;
        }
        const success = computed(()=>{
            return store.getters['subscription/success']
        });
        const subscriber = computed(()=>{
            return store.getters['subscription/getSubscriber']
        });
        const pagination = computed(()=>{
            return store.getters['subscription/getPagination']
        });
        const  spinner =  computed(() =>{ return  store.getters['subscription/processingData']})
        function currencyFormat(amount,currency){
          return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: currency,
          }); 
        }
        function paymentMethods(pm){
            switch(pm){
                case 'PAYPAL':
                    return 'bg-soft-success text-success'
                case 'STRIPE':
                    return 'bg-soft-info text-success'
                case 'N/A':
                    return 'bg-soft-secondary text-dark'
                default:
                    return
            }
        }
        const unSubscribeSuccess = () =>{
           store.commit('subscription/setSuccess',false)
        }
        function changePage(){
           store.dispatch('subscription/getSubscriber',{page:currentPage.value,search:'',rows:rows.value});
        }
        function changeRows(){
            store.dispatch('jobSettings/getPosition',{page:1,search:search.value,rows:rows.value});
        }
        function formatDate(date) {
            return moment(date).format("m/d/y");
        }
         function deleteInclusion(id){
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this action!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, delete it!"
          }).then(result => {
            if (result.value) {
              store.dispatch('subscription/deleteInclusion',id);
              store.dispatch('subscription/getSubscriber',{page:currentPage.value,search:search.value,rows:rows.value});
            }
          });
        }
        onMounted(() => {
             store.dispatch('subscription/getSubscriber',{page:1,search:'',rows:rows.value});
        })
        watch(search , newSearch=>{
            store.dispatch('subscription/getSubscriber',{page:1,search:newSearch,rows:rows.value});
        })
        return {
          paymentMethods,
          rows,
          changeRows,
          showModalCreate,
          showModalEdit,
          formatDate,
          edit,
          deleteInclusion,
          search,
          changePage,
          unSubscribeSuccess,
          success,
          modalCreate,
          modalEdit,
          hideModal,
          subscriber,
          spinner,
          pagination,currentPage,
          currencyFormat
        };
      }
    };
    </script>